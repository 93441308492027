import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "변경된 면회 방침 안내드립니다.",
  "subtitle": "코로나 면회 방침 변경.",
  "date": "2022-06-21T00:00:00.000Z",
  "author": "관리자"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`면회 관련 등 방역수칙 변경`}</h1>
    <h4><em parentName="h4">{`보건복지부 의료기관정책과-3569호에 의거하여 월배요양병원 방역수칙 변경이 있어 알려드립니다.`}</em></h4>
    <ul>
      <li parentName="ul">{`기간 : 2022.06.20(월) ~ 별도 안내시까지`}</li>
      <li parentName="ul">{`변경사항은 아래와 같습니다.`}</li>
    </ul>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`신규입원환자 선제검사`}<br />{`
👉 (현행)입원시 2최 PCR검사 + 4일격리 → 입원시 1회 PCR검사 + 음성확인시까지 격리`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`접촉면회`}<br />{`
👉 면회대상 제한기준폐지(예방접종 관계없이 면회가능)`}<br />{`
👉 `}<strong parentName="p"><span style={{
              "color": "red"
            }}>{`면회객 PCR검사, RAT검사, 신속항원검사 등 음성확인은 유지`}</span></strong></p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": "center"
              }}>{`구 분`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`현 행`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`개 편`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": "center"
              }}>{`환 자`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`4차 접종`}<br /></td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`폐지`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": "center"
              }}>{`면회객`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`3차 접종`}<br />{`(PCR, RAT 등 음성 확인)`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`폐지`}<br /><b>{`(PCR, RAT 등 음성 확인 유지)`}</b></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": "center"
              }}>{`인원제한`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`4명 이하`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`현행 유지`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <ul>
      <li parentName="ul">{`면회 전 방문자 명부작성, 체온측정, 손 소독 등에 협조 바랍니다.`}<br /></li>
      <li parentName="ul">{`면회 도중 마스크를 벗거나 음식섭취는 불가합니다.`}</li>
    </ul>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">
        <p parentName="li">{`외출/외박`}<br />{`
👉 (현행)외래진료 외 외출/외박 금지 → 외래진료 외 4차접종자, 2차이상 접종력과 확진이력이 있는자의 외출/외박 허용`}<br />{`
`}<strong parentName="p">{`※ 외출/외박 복귀시 RAT 또는 PCR검사 실시`}</strong></p>
        <h4 parentName="li">{`면회예약시 안내사항`}</h4>
      </li>
    </ol>
    <ul>
      <li parentName="ul"><strong parentName="li"><span style={{
            "color": "red"
          }}>{`"면회사전예약 필수 및 면회시간 엄수"`}</span></strong></li>
      <li parentName="ul">{`예약전화 가능시간 : 오전10시~오후5시 📞`}<a parentName="li" {...{
          "href": "tel:010-2844-9989"
        }}>{`010-2844-9989`}</a>{`
(예약없이 방문할 경우 면회 불가)`}</li>
      <li parentName="ul">{`면회인원은 4인 이내로 가능하며, `}<u>{`추가인원은 면회 불가`}</u></li>
      <li parentName="ul">{`면회시간 10분으로 제한합니다. (13시 ~ 15시까지 진행)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      